import {
  createResource,
  createSignal,
  Match,
  Show,
  Switch,
  type Component,
} from 'solid-js';
import FormButton from './FormButton';
import RadioField from './RadioField';
import TextArea from './TextArea';
import TextField from './TextField';
import {
  greetName,
  validateForm,
  ValidationError,
} from '../../lib/forms';
import type { FormSubmissionResponse } from '../../data/types';
import Recaptcha from './Recaptcha';

const validateData = async (formData: FormData) => {
  const validatedData = validateForm(formData);

  if (!validatedData.success) {
    const errors = validatedData.error.issues.map((issue) => issue.message);
    throw new ValidationError(`Validation Error: ${errors.join(' & ')}`);
  }

  return validatedData.data;
};

const fetchData = async (formData: FormData) => {
  const response = await fetch(`https://api.nadira.dev/contact`, {
    method: 'POST',
    body: JSON.stringify(formData),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Error submitting form');
  }
  return response.json();
};


const submitForm = async (formData: FormData): Promise<FormSubmissionResponse> => {
  try {
    const validatedData = await validateData(formData);
    const response = await fetchData(validatedData);
    return response;
  } catch (error) {
    throw error;
  }
};

interface FormProps {
  siteKey: string
}

const Form:Component<FormProps> = (props) => {
  const [formData, setFormData] = createSignal<FormData>({
    name: '',
    email: '',
    subject: '',
    thoughts: '',
    recaptchaToken: '' // Include token
  });

  const [submitSuccess, setSubmitSuccess] = createSignal(false);
  const [triggerFetch, setTriggerFetch] = createSignal(false);
  const [submitting, { mutate, refetch }] = createResource(
    () => triggerFetch(),
    async () => {
      const response = await submitForm(formData());
      setTriggerFetch(false);

      if (response.success) {     
        setSubmitSuccess(true)
      } else {
        setSubmitSuccess(false)
      }
    }
  );

  const handleSubmit = async(event: Event) => {
    event.preventDefault();

    resetToken();

    if (!formData().recaptchaToken) {
      console.log("Please complete the recaptcha")
      return
    }

    setTriggerFetch(true); // Trigger the resource fetch
    // Allow refetch after an initial error
    refetch();
  };

  const resetToken = async() => {
    const token = await executeRecaptcha();

    setFormData({
      ...formData(),
      recaptchaToken: token,
    })
  }

  const executeRecaptcha = () => {
    return new Promise((resolve, reject) => {
      if (window.grecaptcha && window.grecaptcha.execute) {
        window.grecaptcha.execute(props.siteKey, {action: 'submit'}).then(resolve, reject);
      } else {
        reject('reCAPTCHA not loaded');
      }
    });
  }

  return (
    <div class="form" id="form__wrapper">
      <Switch>
        <Match when={submitSuccess()}>
          <div class="form__confirmation">
            <h2 class="h4">Thank you</h2>
            <p>
              Thanks reaching out, {greetName(formData().name)}. Be in touch
              soon!
            </p>
          </div>
        </Match>

        <Match
          when={!submitSuccess()
          }
        >
          <form
            onSubmit={handleSubmit}
            class={`${submitting.loading ? 'form__submitting' : ''}`}
          >
            <Show when={submitting.error && !submitting.loading}>
              <div class="form__error">
                <p innerHTML={submitting.error.message} />
              </div>
            </Show>
            <div class="form__fields">
              <TextField
                name="name"
                type="text"
                label="Your Name"
                submitting={submitting.loading}
                value={formData().name}
                onInput={(e) =>
                  setFormData({
                    ...formData(),
                    name: (e.target as HTMLInputElement).value,
                  })
                }
              />
              <TextField
                name="email"
                type="email"
                value={formData().email}
                label="Your Email"
                submitting={submitting.loading}
                onInput={(e) =>
                  setFormData({
                    ...formData(),
                    email: (e.target as HTMLInputElement).value,
                  })
                }
              />
              <RadioField
                name="subject"
                label="Subject"
                value={formData().subject}
                submitting={submitting.loading}
                onInput={(e) =>
                  setFormData({
                    ...formData(),
                    subject: (e.target as HTMLInputElement).value,
                  })
                }
                options={[
                  { label: 'Collaboration' },
                  { label: 'New business' },
                  { label: 'Other' },
                ]}
              />

              <TextArea
                name="thoughts"
                label="Thoughts"
                value={formData().thoughts}
                submitting={submitting.loading}
                onInput={(e) =>
                  setFormData({
                    ...formData(),
                    thoughts: (e.target as HTMLTextAreaElement).value,
                  })
                }
              />
              <Recaptcha 
                siteKey={props.siteKey}
                onVerify={(token) => setFormData({
                  ...formData(),
                  recaptchaToken: token,
                })}
                />
              <FormButton submitting={submitting.loading} type="submit">
                Connect
              </FormButton>
            </div>
          </form>
        </Match>
      </Switch>
    </div>
  );
};

export default Form;
