
import { z } from 'zod';

export class ValidationError extends Error {
    constructor(message) {
        super(message);
        this.name = "Validation Error";
    }
}


export const formDataToObject = (formData: FormData) => {
    const obj: { [key: string]: string } = {};
    formData.forEach((value, key) => {
        obj[key] = value as string;
    });
    return obj;
}

export const delay = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export const validateForm = (data: FormData) => {

    const formSchema = z.object({
        name: z.string().min(2, { message: "Please provide your name so I'll know what to call you" }),
        email: z
            .string()
            .email({ message: "Double check that your email address is valid" }),
        subject: z.string().optional(),
        thoughts: z
            .string()
            .min(16, { message: "Give me more of an idea of what this is about" }),
        recaptchaToken: z.string().optional() // IMPROVEMENT: made this optional for debugging but can be removed
    });

    return formSchema.safeParse(data);
}

/** 
 * Get first name
 * https://stackoverflow.com/a/40141884 
 * 
 * 
 */
export const greetName = (name: string): {name: string, lastName?: string, secondLastName?: string | undefined, middleName?: string | undefined} => {
    var fullName = capitalizeFirstLetter(name) || "";
    var result = {}

    if (fullName.length === 0)
        return { name };

    const nameTokens = fullName.match(/(?:(?:[A-Za-zÁ-ÚÑÜ][a-zá-úñü]+){1,2})|(?:(?:[aeodlsz]{1,3}[ ]){0,2}[A-ZÁ-ÚÑÜ][a-zá-úñü]+)/gms) || [];

    let greetName

    switch(nameTokens.length) {
        case 1:
            [result.name] = nameTokens;
            break;
          case 2:
            [result.name, result.lastName] = nameTokens;
            break;
          case 3:
            [result.name, result.lastName, result.secondLastName] = nameTokens;
            break;
          default:
            [result.name, result.middleName, result.lastName, result.secondLastName] = nameTokens;       
    }

    greetName = result.name || name;

    if (result.middleName)
        greetName += ` ${capitalizeFirstLetter(result.middleName)}`

    return greetName;
}

const capitalizeFirstLetter = (input: string): string => {
    if (!input.length)
        return input

    return input[0].toUpperCase() + input.slice(1);
}